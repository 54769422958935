@import '../../colors.module.scss';

.appBar {
  padding: 0 !important;
  box-shadow: none !important;
}

.toolbar {
  background-color: $darkNavyBlue;
  height: 64px;
  margin-left: -24px;
  margin-right: -24px;

  .logo {
    color: $white;
  }

  .logoutButton {
    color: $blue;
    margin-left: auto;
    font-size: 16px;
    background-color: $white !important;
    border: solid 1px $blue;
    line-height: normal;
    min-width: 101px;
    height: 32px;
  }

  .logoutButton:hover{
    color: $hoverBlue;
    border: solid 1px $hoverBlue;
    border-radius: 23px;
    min-width: 101px;
    box-shadow: 0 5px 3px -2px rgba(33, 33, 33, 0.16), 0 4px 6px 0 rgba(0, 0, 0, 0.18), 0 4px 6px 0 rgba(0, 0, 0, 0.04);
  }
}
