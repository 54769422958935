@import '../../../../../common/colors.module.scss';
@import '../../../../../common/mixins.scss';

.dropdownIndicator {
  padding-right: 0 !important;
  &:hover {
    cursor: pointer;
  }
}

.arrowDropDown {
  color: $darkGray;
}
