@import '../../mixins.scss';

.paper {
  display: flex;
  min-height: 100vh;
  padding-top: 0 !important;

  @include breakpoint(md) {
    margin: 80px auto;
    width: 600px;
    min-height: initial;
  }
}
