@import '../../mixins.scss';

.outerContainer {
  display: flex;
  min-height: 100vh;
}

.innerContainer {
  flex: 1;
  min-height: 100vh;
  background-image: linear-gradient(to bottom, #030122, rgba(0, 71, 33, 0.4) ), url('./concert1440x959.png');
  background-repeat: no-repeat;
  background-size: cover;
  min-height: initial;
  @include breakpoint(md) {
  }
}
