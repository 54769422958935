.mainGrid {
  display: flex;
  align-items: flex-start;
  border: 1px solid #9e9e9e;
  padding: 16px;
}

.checkbox {
  padding: 0 14px 0 0 !important;
}

.linkA, .linkB, .linkC {
  text-decoration: underline;
}
