@import '../colors.module.scss';
@import '../mixins.scss';

.adornment {
  padding: 24px 10px;
  background-color: $blue;
  color: $white;
  font-size: 29px;
  &:hover {
    cursor: pointer;
  }
}

.input {
  background-color: $white;
  border: 1px solid $black;
}

.inputText {
  padding: 13px 8px 14px !important;
}

.snackbarContent {
  background-color: $darkerGray !important;
}

.message {
  color: $white !important;
}

@include breakpoint(md) {
  .snackbarContent {
    background-color: $white !important;
  }
  
  .message {
    color: $darkerGray !important;
  }
} 
