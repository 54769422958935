@import "../../../common/colors.module.scss";
@import "../../../common/mixins.scss";

.phoneNumber {
  margin: 16px 0 24px 0;
  padding-top: 13px;
}

.phoneNumber > input {
  height: 55px;
}

.phoneNumber input {
  padding-bottom: 8px !important;
}

.phoneNumber > div > div {
  height: 55px;
}

.phoneNumber > div > div:first-of-type{
  height: 55px;
  width: 40px;
  padding-left: 5px;
  background-color: $lighterGray;
  border-bottom: 1px solid rgba(0, 0, 0, 0.42);
}