@mixin breakpoint($point) {
    @if $point == sm {
        @media (min-width: 600px) { @content; }
    }
    @if $point == md {
        @media (min-width: 960px) { @content; }
    }
    @if $point == lg {
        @media (min-width: 1280px) { @content; }
    }
    @if $point == xl {
        @media (min-width: 1920px) { @content; }
    }
}
