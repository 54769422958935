@import '../../common/mixins.scss';

@media only screen and (min-width: 1000px) {
  .welcomeContainer {
    padding-top: 20px !important;
  }
}

.footer {
  width: 100%;
  position: fixed;
  background-color: rgba(20,45,38,.92);
  text-align: center;
  color: white;
  bottom: 0;
  padding-top: 20px;
  padding-bottom: 15px;
}

.loginText {
  margin-top: 15px;
}

.iframeWrapper {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  height: 0;
  width: 100%;
  margin-top: 15px;
  margin-bottom: 15px;
}

.iframeWrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.content {
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 125px;
}

.mpTalkPoints {
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  background-color: #ECEFF0;
  border-radius: 25px;
  padding: 1.2rem;
  font-weight: bold;
}

.colLeft,
.colRight {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-evenly;
  flex: 1;
}

.colMid {
  text-align: center;
  max-width: 190px;
  margin: 0 .25rem;
}

.mpRegIcon{
  display: block;
  position: relative;
  max-width: 70px;
}

.reasonText {
  font-weight: 700 !important;
}