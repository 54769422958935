@import '../../../common/colors.module.scss';
@import '../../../common//mixins.scss';

.createIcon {
  cursor: pointer;
}

.editText {
  color: $blue;
  cursor: pointer;
}

.resendLink :hover {
  box-shadow: none !important;
  color: $darkNavyBlue;
}

.message {
  color: $white !important;
}

@include breakpoint(md) {
  .snackbarContent {
    background-color: $white !important;
  }

  .message {
    color: $darkerGray !important;
    background-color: $white;
  }
}
