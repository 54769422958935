@import '../../mixins.scss';

.paper {
  display: flex;
  min-height: 100vh;
  overflow: hidden;
  padding-left: 0 !important;
  padding-right: 0 !important;
  width: 96%;
  margin: 10px auto;

  @include breakpoint(md) {
    margin: 80px auto;
    width: 700px;
    min-height: initial;
  }
}
