@import '../../../common/colors.module.scss';

.signupError {
  .container {
    display: flex;
    background-color: $red;
    margin: -24px -24px 0 -24px;
    color: #ffffff;
    padding: 16px;
  }

  .errorIcon {
    margin-right: 16px;
    align-self: center;

  }

  .title {
    margin-bottom: 10px; 
    font-weight: bold; 
    color: #fff
  }

  .text {
    color: #fff;
    a {
      color: #fff;
      text-decoration: underline;
    }
  }

  .triangle {
    margin: auto;
    width: 0;
    height: 0;
    border-top: 14px solid $red;
    border-right: 14px solid transparent;
    border-left: 14px solid transparent;
  }
}