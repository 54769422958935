@mixin centering {
  display: flex;
  align-items: center;
  justify-content:center;
}

@mixin centered {
  vertical-align: middle;
}

.loadingContainer {
  @include centering;
  height: 100vh;

  .logo {
    @include centered;
  }
}
