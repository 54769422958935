@import '../colors.module.scss';

.card {
  border-radius: 0 !important;
  background-color: $lightGray2 !important;
  padding: 0px !important;
  width: 100% !important;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.35) !important;
  margin-bottom: 8px !important;
}

.cardHeader {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}

.iconButton {
  padding: 0 !important;
  margin: 6px 8px 0 !important;
  &:hover {
    background-color: !important;
  }
}

.personIcon {
  font-size: 24px !important;
}

.editIcon {
  font-size: 21.25px !important;
}

.clearIcon {
  font-size: 27.25px !important;
}
