.addPersonButton {
  width: 139px;
}

.addAnotherButton {
  width: 215px;
}

.actionSection {
  padding-top: 16px;
}
