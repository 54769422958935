@import '../../../common/colors.module.scss';
@import '../../../common/mixins.scss';


.messageContainer {
  padding-top: 10px !important;
}

.validateMessage {
  font-size: 14px;
  padding-top: 5px !important;
  padding-bottom: 0px !important;
}

.valid {
  font-size: 14px !important;
  color: $green;
}

.invalid {
  font-size: 14px !important;
  color: $red;
}

.visibilityButton {
  padding: 0px !important;
}
