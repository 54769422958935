@import '../../colors.module.scss';

.appBar {
  padding: 0 !important;
  box-shadow: none !important;
}

.toolbar {
  background-color: $white;
  height: 64px;

  .logo {
    color: $black;
  }

  .logoutButton {
    color: $blue;
    font-size: 16px;
    margin-left: auto;
    background-color: transparent !important;
    border: solid 1px $blue;
    border-radius: 23px;
    line-height: normal;
    min-width: 101px;
    height: 32px;
  }

  .logoutButton:hover{
    color: $hoverBlue;
    border: solid 1px $hoverBlue;
    border-radius: 23px;
    min-width: 101px;
  }
}
