$black: #212121;
$white: #FFF;

$lighterGray: #F5F5F5;
$lightGray2: #eeeeee;
$lightGray: #e0e0e0;
$gray: #bdbdbd;
$gray2: #ababab; // input placeholder color
$darkGray: #616161;
$darkerGray: #424242;

$blue: #325fff;
$medBlue: #0d47a1;
$darkNavyBlue: #1f223b;
$hoverBlue: #0036cb;
$green: #008740;

$red: #d81a29;
$lightRed: #fce7e5;

// this allows you to use the variables in tsx files
:export {
  black: $black;
  white: $white;

  lighterGray: $lighterGray;
  lightGray2: $lightGray2;
  lightGray: $lightGray;
  gray: $gray;
  gray2: $gray2;
  darkGray: $darkGray;
  darkerGray: $darkerGray;

  blue: $blue;
  medBlue: $medBlue;
  darkNavyBlue: $darkNavyBlue;
  hoverBlue: $hoverBlue;
  green: $green;

  red: $red;
  lightRed: $lightRed;
}
