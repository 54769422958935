@import '../../../common/colors.module.scss';

.paper {
  background-color: $lighterGray !important;
  padding: 24px 8px !important;
  box-shadow: none !important;
  border: solid 1px $lightGray;
}

.heading {
  font-weight: bold !important;
}
