@import '../../../../../common/colors.module.scss';

.selectPaper {
  border: 1px solid $gray;
  border-radius: 0 !important;
  padding: 0 !important;
  box-shadow: none !important;
};

.menuItem {
  padding: 12px 24px !important;
}

.emptySelect {
  color: $darkGray !important;
}

.placeholder {
  color: $darkGray;
  background-color: $white !important;
  border-bottom: 1px solid $gray !important;
  padding-top: 16px !important;
  padding-bottom: 24px !important;
  margin-bottom: 12px !important;
}
